
























































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { settingsServices } from "@/services/settings.service";
import { DataCurrency, DataListCurrency, DataMasterCurrency} from "@/models/interface/settings.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import {debounceProcess} from "@/helpers/debounce";
import {changeCurrencytoNumeric, currencyFormat} from "@/validator/globalvalidator";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "CurrencyIndex",
  components: {
    TableCurrencyList: () => import("./table/CurrencyList.vue")
  },
  data() {
    this.handleSearchSelectTable = debounceProcess(this.handleSearchSelectTable, 400);
    return {
      formatCurrencytoNumber:changeCurrencytoNumeric,
      totalData: 0 as number,
      direction: "" as string,
      form: this.$form.createForm(this, { name: "currency" }),
      isFormSubmitted: false as boolean,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      dataListCurrency: [] as DataCurrency[],
      search: "" as string,
      sort: "" as string,
      authPrivilege: [] as string[],
      listDeletedCurrency: [] as any[],
      labelCol: { span: 6 },
      selectedRowKeys: [] as number[],
      wrapperCol: { span: 16 },
      titlemodalOpen: "Currency" as string,
      trigerdisable: false as boolean,
      loadingTable: false as boolean,
      columnsTable: [
        {
          title: this.$t("lbl_from_currency"),
          dataIndex: "fromCurrencyId",
          key: "currencyfrom",
          width: 100,
          scopedSlots: { customRender: "fromCurrencyId" },
          responsiveColSelect: [
            {
              name: "fromCurrencyId",
              placeholder: "Type for more data",
              style: "width: 100%;",
              value: "id",
              options: [] as DataListCurrency[]
            },
            {
              name: "toCurrencyId",
              placeholder: "Type for more data",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as DataListCurrency[]
            },
          ],
          responsiveColInput: [
            {
              name: "rate",
              placeholder: "Rate",
              style: "width: 100%;",
              disabled: "",
            },
          ],
          responsiveColRangePicker: [
            {
              name: "rangePicker",
              placeholder: "Date",
              style: "width: 100%;",
              disabled: ""
            },
          ]
        },
        {
          title: this.$t("lbl_to_currency"),
          dataIndex: "toCurrencyId",
          key: "currencyto",
          width: 100,
          scopedSlots: { customRender: "toCurrencyId" },
        },
        {
          title: this.$t("lbl_rates"),
          dataIndex: "rate",
          key: "rate",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "rate" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "rangePicker",
          key: "date",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "rangePicker" },
        },
      ] as any,
      mForm: false,
      listCurrency: [] as DataMasterCurrency[]
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "accounting-currency" && x.privilege.update && x.privilege.create);
    },
  },
  mounted() {
    this.getListOfCurrency("");
    this.getListOfMasterCurrency("");
  },
  methods: {
    moment,
    handleSearchSelectTable (value , record , name) {
      let params = {} as RequestQueryParamsModel;
      if(value) params.search = `currencyCode~*${value}*_OR_description~*${value}`;
      if(!value) params.page = 0;
      const findColumn = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
      if(findColumn) {
        findColumn[findColumn.findIndex(data => data.name === name)].loading = true;
      }
      settingsServices
        .listOfMasterCurrency(params, "")
        .then((data: any) => {
          data.data.map((dataObject, index) => (dataObject.key = index , dataObject.name = dataObject.currencyCode));
          if(findColumn) {
          findColumn[findColumn.findIndex(data => data.name === name)].options = data.data;
          this.columnsTable = this.columnsTable.slice();
        }
        })
      .finally(() => {if (findColumn) findColumn[findColumn.findIndex(data => data.name === name)].loading = false;});
    },
    handleDatePicker (date,key ,  dateString) {
      this.dataListCurrency[key]["fromDate"] = date[0]._d;
      this.dataListCurrency[key]["toDate"] = date[1]._d;
    },
    showConfirmation () {
      if(this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: "Do you want to delete these items?",
          content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {return;},
        });
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select at least one row to delete",
        });
      }
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.ResetFilter(false);
      }
      this.getListOfMasterCurrency("");
    },
    handleSelectTable(value, key, col, recordOptions): void {
      this.dataListCurrency[key] = {...this.dataListCurrency[key],[col] :value};
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    saveRateCurrency():void{
      const datasave = [] as any;
      this.dataListCurrency.forEach(item => {
        let dataObj = {
        fromCurrencyId: item.fromCurrencyId ? item.fromCurrencyId : null,
        fromDate: item.fromDate ? this.moment(item.fromDate).format() : null,
        rate: item.rate ? this.formatCurrencytoNumber(item.rate+"") : null,
        toCurrencyId: item.toCurrencyId ? item.toCurrencyId : null,
        toDate: item.toDate ? this.moment(item.toDate).format() : null
      };
      if (item.id) dataObj["conversionSecureId"] = item.id;
        datasave.push(dataObj);
      });
      const datapushCurrency = {
        deletedIds:this.listDeletedCurrency,
        requestDTO:datasave
      };
      settingsServices
        .createCurrency(datapushCurrency)
        .then(() => {
          this.listDeletedCurrency = [];
          this.getListOfCurrency("");
          this.$message.success(Messages.CREATE_SUCCESS);
        });
    },
    handleInput (value, key, objectColInput, objectColInputName: string, onEvt: string) {
      let formatedVal: string | number = value;
      if (onEvt === "onBlur") {
        formatedVal = currencyFormat(value);
      } else if (onEvt === "onFocus") {
        formatedVal = this.formatCurrencytoNumber(value);
      }
      this.dataListCurrency[key][objectColInput.name] = formatedVal;
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    handleInputNumber (value, key, objectColInputNumber) {
      this.dataListCurrency[key][objectColInputNumber.name] = value;
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    handleDate (value, key, objectColDate,dateString) {
      this.dataListCurrency[key][objectColDate.name] = dateString;
      this.dataListCurrency = this.dataListCurrency.slice();
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleDeleteRow () {
      this.dataListCurrency = this.dataListCurrency.filter((data) => {
        if (this.selectedRowKeys.includes(data.key)){
          this.listDeletedCurrency.push(data.id);
        }
        return !this.selectedRowKeys.includes(data.key);
      });
      this.dataListCurrency.map((data, index) => data.key = index);
      this.dataListCurrency = this.dataListCurrency.slice();
      this.totalData = this.dataListCurrency.length;
      this.selectedRowKeys = [];
    },
    handleAddRow():void{
      this.dataListCurrency = [...this.dataListCurrency, {
          fromCurrencyId:null,
          id:null,
          key:this.dataListCurrency.length,
          rate:null,
          toCurrencyId:null,
          toDate:null,
          fromDate:null,
      }];
      this.totalData = this.dataListCurrency.length;
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfCurrency("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfCurrency("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfCurrency("");
    },
    getListOfCurrency(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts:"createdDate:desc"
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      settingsServices
        .listOfCurrency(params,"")
        .then((res: any) => {
          if (!path) {
            res.data.map((dataObject, index) => (dataObject.rate = currencyFormat(dataObject.rate) ,dataObject.key = index , dataObject.rangePicker = dataObject.fromDate ? [this.moment(dataObject.fromDate).format("YYYY-MM-DD"),this.moment(dataObject.toDate).format("YYYY-MM-DD")] : ""   ) );
            this.dataListCurrency = res.data;
            this.totalData = res.totalElements;
          } else {
            setTimeout(() => {
              this.form.setFieldsValue({
                fromCurrencyId: res.fromCurrencyId,
                rate: res.rate,
                toCurrencyId: res.toCurrencyId,
              });
            }, 200);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingTable = false));
    },
    getListOfMasterCurrency(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: 1000,
          page: 0,
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      settingsServices
        .listOfMasterCurrency(params, path)
        .then((res: any) => {
          res.data.map((dataObject, index) => (dataObject.key = index , dataObject.name = dataObject.currencyCode));
          this.listCurrency = res;
          const findColumn = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
          if(findColumn) {
            findColumn[0].options = res.data;
            findColumn[1].options = res.data;
                     }
        });
    },
    async createCurrency(data: {code: string, description: string}): Promise<void> {
      try {
        const req = {
          currencyCode: data.code,
          description: data.description,
        };
        this.isFormSubmitted = true;
        await settingsServices.createMasterCurrency(req);
        this.getListOfCurrency("");
        this.getListOfMasterCurrency("");
        this.form.resetFields();
        this.$message.success(this.$t("notif_create_success").toString());
        this.closeForm();
      } catch (error) {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("lbl_error_general_description").toString(),
        });
      } finally {
        this.isFormSubmitted = false;
      }
    },
  },
});
