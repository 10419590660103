var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-card",
            { attrs: { title: _vm.$t("lbl_currency") } },
            [
              _c("TableCurrencyList", {
                on: {
                  onCurrCreated: function($event) {
                    return _vm.getListOfMasterCurrency("")
                  }
                }
              }),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.$t("lbl_rates")))])
                  ]),
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "mr-2",
                              attrs: { icon: "plus", type: "primary" },
                              on: { click: _vm.handleAddRow }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "mr-2",
                              attrs: { icon: "delete", type: "danger" },
                              on: { click: _vm.showConfirmation }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("TableCustom", {
                        attrs: {
                          idtable: "table1",
                          "data-source": _vm.dataListCurrency,
                          columns: _vm.columnsTable,
                          scroll: { x: "calc(700px + 50%)" },
                          paginationcustom: true,
                          "default-pagination": false,
                          loading: _vm.loadingTable,
                          "on-select-change": _vm.onSelectChange,
                          "selected-row-keys": _vm.selectedRowKeys,
                          "handle-input": _vm.handleInput,
                          "handle-input-number": _vm.handleInputNumber,
                          "handle-date": _vm.handleDate,
                          "handle-select": _vm.handleSelectTable,
                          "handle-date-picker": _vm.handleDatePicker,
                          "handle-search-select-table":
                            _vm.handleSearchSelectTable
                        },
                        on: { "on-tablechange": _vm.onSorterChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-space",
                        { attrs: { align: "center" } },
                        [
                          _c("Pagination", {
                            attrs: {
                              total: _vm.totalData,
                              "page-size-set": _vm.limit,
                              "id-pagination": "pagination1"
                            },
                            on: {
                              "response-pagesize-change":
                                _vm.responsePageSizeChange,
                              "response-currentpage-change":
                                _vm.responseCurrentPageChange
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12, align: "end" } },
                    [
                      _c("a-tag", { attrs: { color: "grey" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_total_found")) +
                            " : " +
                            _vm._s(_vm.totalData) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-col",
                        { attrs: { span: 24, align: "end" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "save" },
                              on: { click: _vm.saveRateCurrency }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }